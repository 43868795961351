<template>
    <div
        name="panel"
        class="template"
    >
        <main class="template__main">
            <slot name="default" />
        </main>

        <div
            v-if="!!$slots.related"
            class="template__related-gradient"
        />

        <aside
            v-if="!!$slots.related"
            class="template__related"
        >
            <div class="template__related__body">
                <slot name="related" />
            </div>
        </aside>
    </div>
</template>
