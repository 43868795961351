<template>
    <div class="event-member-actions">
        <BaseButton
            v-if="
                userStore.loggedIn &&
                    event.isFullyBooked &&
                    isInWaitingList &&
                    !hasReservation
            "
            class="button--secondary"
            @click="openReservationModal"
        >
            Op wachtlijst
            <template #after>
                <BaseIcon icon="check" />
            </template>
        </BaseButton>

        <BaseButton
            v-else-if="userStore.loggedIn && !hasReservation"
            :disabled="!!disabled"
            class="button--secondary"
            @click="openReservationModal"
        >
            {{ reserveText }}

            <template
                v-if="showButtonIcon"
                #after
            >
                <BaseIcon icon="arrow-right" />
            </template>
        </BaseButton>

        <BaseButton
            v-else-if="userStore.loggedIn && hasReservation"
            class="button--secondary"
            @click="openReservationModal"
        >
            Gereserveerd
            <template #after>
                <BaseIcon icon="check" />
            </template>
        </BaseButton>

        <BaseButton
            v-else-if="!userStore.loggedIn"
            :disabled="!!disabled"
            class="button--secondary"
            @click="modalStore.open({ name: MODAL_LOGIN })"
        >
            {{ reserveText }}
            <template
                v-if="showButtonIcon"
                #after
            >
                <BaseIcon icon="arrow-right" />
            </template>
        </BaseButton>

        <ToggleButton
            :value="hasFavorite ? [props.event.id] : []"
            :toggle-value="event.id"
            class="toggle-button--favorite"
            @input="handleFavoriteSelect"
        >
            <template #icon-inactive>
                <BaseIcon icon="save" />
            </template>

            <template #icon-active>
                <BaseIcon icon="saved" />
            </template>

            {{ hasFavorite ? 'Bewaard' : 'Bewaar' }}
        </ToggleButton>
    </div>
</template>

<script setup lang="ts">
import { MODAL_LOGIN, MODAL_RESERVATION, useModalStore } from '~/store/modal';
import { STATUS } from '~/constants/errorMessages';
import * as RESERVATION_TYPES from '~/constants/reservationTypes.js';
import { useUserStore } from '~/store/user';
import { useEventsStore } from '~/store/events';
import type { EventDate } from '~/@types/data';

const props = defineProps<{
    event: EventDate;
}>();

const userStore = useUserStore(),
    modalStore = useModalStore(),
    eventsStore = useEventsStore();

const savingFavorite = ref(false);
const errorMessage = ref<string|null>(null);

const isInWaitingList= computed(() => {
        return userStore.isInWaitingList(props.event.id);
    }),
    hasReservation = computed(() => {
        return userStore.hasReservation(props.event.id);
    }),

    hasFavorite = computed(() => {
        return userStore.hasFavorite(props.event.id);
    }),

    isWapresFullyBooked = computed(() => {
        return props.event.reservationType === RESERVATION_TYPES.WAPRES
            && props.event.isFullyBooked
            && !props.event.doorsaleEnabled;
    }),

    reservationState = computed(() => {
        if (props.event.isCancelled) {
            return 'cancelled';
        }

        if (props.event.isSoldout) {
            return 'sold-out';
        }

        if (isWapresFullyBooked.value && !isInWaitingList.value && props.event.waitingListEnabled) {
            return 'waiting-list';
        }

        if (isWapresFullyBooked.value) {
            return 'fully-booked';
        }

        if (!props.event.isActive) {
            return 'inactive';
        }

        if (!userStore.loggedIn) {
            return 'log-in';
        }

        return 'default';
    }),

    reserveText = computed(() => {
        if (reservationState.value === 'cancelled') {
            return 'Dit programma is afgelast';
        }

        if (reservationState.value === 'sold-out') {
            return 'Dit programma is uitverkocht';
        }

        if (reservationState.value === 'fully-booked') {
            return 'Volgereserveerd';
        }

        if (reservationState.value === 'inactive') {
            return 'Dit programma is al geweest';
        }

        if (reservationState.value === 'log-in') {
            return 'Log in om te reserveren';
        }

        return 'Tickets';
    }),

    showButtonIcon = computed(() => {
        if (reservationState.value !== 'default') {
            return false;
        }

        return true;
    }),

    disabled = computed(() => {
        const disabledStates = [
            'sold-out',
            'fully-booked',
            'inactive',
            'cancelled'
        ];

        return disabledStates.includes(reservationState.value);
    });

const openReservationModal = () => {
    modalStore.open({
        name: MODAL_RESERVATION,
        data: props.event
    });
};

const handleFavoriteSelect = async(value: number[]) => {
    if (!userStore.loggedIn) {
        modalStore.open({ name: MODAL_LOGIN });
        return;
    }

    savingFavorite.value = true;

    await useLaravelApi(`/v2.0/user/me/favorite/${props.event.id}`, {
        method: value.length === 0 ? 'DELETE' : 'POST'
    }).catch((error) => {
        if (error?.response?.status === 500) {
            errorMessage.value = STATUS['500'];
        }
        savingFavorite.value = false;
    });

    // Refresh the user, so we have the most recent favorites.
    eventsStore.updateMyListHome();

    await userStore.fetchUser();

    savingFavorite.value = false;
};
</script>

<style lang="less" src="./EventMemberActions.less" />
