<template>
    <StateSwitch :state="status">
        <template #default>
            <slot
                name="default"
                :event="event?.data"
            />
        </template>
    </StateSwitch>
</template>

<script setup lang="ts">
import type { EventDate } from '~/@types/data';
import { useEventsStore } from '~/store/events';

const props = defineProps<{
    eventId: string|number;
}>();

const eventsStore = useEventsStore();

const fetchEventUrl = computed(() => {
    return `/v2.0/events/${props.eventId}`;
});

const { data: event, status, error } = await useAsyncData<{data: EventDate}>(
    fetchEventUrl.value,
    () => useLaravelApi(fetchEventUrl.value, { params: { use_cache: eventsStore.needsRefresh === 0 }}),
    {
        watch: [() => props.eventId, () => eventsStore.needsRefresh]
    }
);

const seoDescription = computed(() => {
    if (event && event.value?.data?.description) {
        return event.value.data.description.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/g, '');
    }

    return null;
});

watch(event, () => {
    if (event.value?.data) {
        eventsStore.setActiveEventData(event.value.data as EventDate);
    }
}, {
    immediate: true
});

if (!event.value?.data) {
    throw createError({ statusCode: 404, statusMessage: JSON.stringify(error.value) });
} else {
    useHead({
        title: event.value?.data.title,
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: seoDescription.value
            },
            {
                hid: 'author',
                name: 'author',
                content: 'We Are Public'
            },
            {
                hid: 'og:image',
                property: 'og:image',
                content: event.value.data.featuredImage?.url_box_lg
            },
            {
                hid: 'og:title',
                property: 'og:title',
                content: event.value.data.title
            },
            {
                hid: 'og:description',
                property: 'og:description',
                content: seoDescription.value
            },
            {
                hid: 'twitter:image',
                name: 'twitter:image',
                content: event.value.data.featuredImage?.url_box_lg
            }
        ]
    });
}

watch(event, () => {
    if (import.meta.server) {
        return;
    }

    setTimeout(function() {
        document.querySelector('.event-modal')?.scroll({
            top: 0,
            behavior: 'smooth'
        });
    }, 300);
}, {
    immediate: true
});
</script>
