<template>
    <FlexibleSectionEventBlock
        v-if="event.start_date && !event.end_date && eventHasCity"
        :title="`Ook op deze dag in ${event.venue.city.name}`"
        :event-bundle="relatedCityEventBundle"
        :event-bundle-entry="[]"
        event-block-presentation-type="slider"
    />

    <FlexibleSectionEventBlock
        v-if="event.disciplines && event.disciplines.length > 0 && eventHasCity"
        :title="`Meer ${event.disciplines[0].name} in ${event.venue.city.name}`"
        :event-bundle="relatedDisciplineEventBundle"
        :event-bundle-entry="[]"
        event-block-presentation-type="slider"
    />
</template>

<script>
import {
    parseISO,
    format
} from 'date-fns';

export default {
    props: {
        event: {
            type: Object,
            required: true
        }
    },

    computed: {
        eventHasCity() {
            return this.event?.venue?.city;
        },

        relatedCityEventBundle() {
            if (!this.event.venue || !this.event.venue.city || !this.event.venue.city.id) {
                return [{}];
            }

            const startDateObj = parseISO(this.event.start_date);
            const formattedDate = format(startDateObj, 'dd-MM-yyyy');

            return [{
                __typename: '[custom]event_bundle_related',
                startDate: formattedDate,
                city: [
                    {
                        laravelModelId: this.event.venue.city.id
                    }
                ],
                skipEvent: this.event.id
            }];
        },

        relatedDisciplineEventBundle() {
            if (!this.event.venue || !this.event.venue.city || !this.event.venue.city.id || !this.event.disciplines.length) {
                return [{}];
            }

            return [{
                __typename: '[custom]event_bundle_related',
                discipline: [
                    {
                        laravelModelId: this.event.disciplines[0].id
                    }
                ],
                city: [
                    {
                        laravelModelId: this.event.venue.city.id
                    }
                ],
                skipEvent: this.event.id
            }];
        }
    }
};
</script>
