<template>
    <BaseHero
        v-if="event"
        class="hero--event"
        :image="featuredImage"
    >
        <template #preTitle>
            <EventDisciplines :event="event" />

            <BaseLabel v-if="programLine">
                {{ programLine }}
            </BaseLabel>
        </template>

        <template #title>
            {{ event.oneLiner }}
        </template>

        <template #description>
            <EventMeta
                :event="event"
                class="event-meta--event-detail"
            />

            <EventMemberActions
                :event="event"
                class="hero__actions"
            />

            <p v-if="event.isCancelled">
                Alle gemaakte reserveringen komen te vervallen
            </p>

            <p v-else-if="isNotActive">
                Je kunt niet meer reserveren
            </p>

            <p v-else-if="isNotReservable">
                Reserveren is helaas niet meer mogelijk
            </p>

            <EventPriceParagraph
                v-else
                :event="event"
            />
        </template>
    </BaseHero>
</template>

<script setup lang="ts">
import type { Media_Asset_Extended } from '~/@types/craft-schema.extended';
import type { EventDate } from '~/@types/data';
import { useEventsStore } from '~/store/events';

const props = defineProps<{event: EventDate }>();

const eventsStore = useEventsStore();

const programLine = props.event.programLine
    ? props.event.programLine.name
    : null;


const isNotReservable = computed(() =>
    (
        !eventsStore.isWaitingListEnabled
        && eventsStore.isFullyBooked
    )
    || props.event.isSoldout
);

const isNotActive = !props.event.isActive;

const featuredImage: Media_Asset_Extended = {
    __typename: 'media_Asset',
    url_hero_xs: props.event.featuredImage?.url_hero_xs,
    url_hero_sm: props.event.featuredImage?.url_hero_sm,
    url_hero_md: props.event.featuredImage?.url_hero_md,
    url_hero_lg: props.event.featuredImage?.url_hero_lg,

    webp_url_hero_xs: props.event.featuredImage?.url_hero_xs_webp,
    webp_url_hero_sm: props.event.featuredImage?.url_hero_sm_webp,
    webp_url_hero_md: props.event.featuredImage?.url_hero_md_webp,
    webp_url_hero_lg: props.event.featuredImage?.url_hero_lg_webp,

    alt: props.event.featuredImage?.alt,
    width: props.event.featuredImage?.width,
    height: props.event.featuredImage?.height,
    extension: '',
    filename: props.event.featuredImage?.conversion_name,
    folderId: 0,
    hasFocalPoint: false,
    kind: '',
    path: ''
};
</script>

<style lang="less" src="./EventHero.less"></style>
